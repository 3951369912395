import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const New = ({ logOut, title_page }) => {
  document.title = title_page;
  const [isps, setIsps] = useState([]);
  const [mikrotiks, setMikrotiks] = useState([]);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  const [isp, setIsp] = useState(-1);
  let history = useNavigate();
  const id_role = localStorage.getItem("id_role");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "isps")
      .then((response) => setIsps(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    console.log(parameters);

    setLoadingAdd(true);
    axios
      .post(process.env.REACT_APP_API + "zones", parameters)
      .then((response) => {
        console.log("Success", response);
        setModal({
          title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
          body: response.data.message,
          secondary: response.data.success ? "TERMINAR" : null,
          primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
        });
        openModal();
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const token = localStorage.getItem("token_user");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    setIsp(selectedValue);

    axios
      .get(process.env.REACT_APP_API + "mikrotiks?isp=" + selectedValue, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMikrotiks(response.data.data);
      })
      .catch((AxiosError) => {
        if (AxiosError.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          console.log(AxiosError.response.status);

          if (AxiosError.response.status === 401) {
            logOut();
          }
        } else if (AxiosError.request) {
          alert(
            "No se ha recibido una respuesta del servidor. Si el problema persiste contacte al administrador."
          );
          // La solicitud fue realizada pero no se recibió ninguna respuesta
          console.log(AxiosError.request);
        } else {
          // Ocurrió un error durante la solicitud
          console.log("Error", AxiosError.message);
        }
      });
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>Nueva Zona</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>Zona</Card.Header>
              <Card.Body>
                <Row>
                  {id_role == 2 && (
                    <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                      <Form.Group controlId="isp_zone">
                        <Form.Label>ISP:</Form.Label>
                        <Form.Control
                          as="select"
                          {...register("isp_zone")}
                          onChange={handleSelectChange}
                        >
                          <option key="0" value="0">
                            {isps.length === 0
                              ? "Cargando..."
                              : "SELECCIONE UN ISP"}
                          </option>
                          {isps.map((option) => (
                            <option key={option.id_isp} value={option.id_isp}>
                              {option.name_isp}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.isp_zone && (
                          <span style={{ color: "red" }}>
                            {errors.isp_zone.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_zone">
                      <Form.Label>NOMBRE:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name_zone")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="cp_zone">
                      <Form.Label>Código Postal:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("cp_zone")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h4>Mikrotik que administra esta zona</h4>
                </Row>
                <Row xs={2} md={4} lg={5}>
                  {mikrotiks.map((mikrotik, index) => (
                    <Form.Check
                      key={mikrotik.id_mikrotik}
                      type="radio" // Change the type to "radio"
                      label={mikrotik.name_mikrotik}
                      id={`mikrotiks-radio-${mikrotik.id_mikrotik}`}
                      className="mb-3"
                      name="mikrotik_zone" // Add a common name for radio button grouping
                      {...register("mikrotik_zone")}
                      value={mikrotik.id_mikrotik} // Set the value for each radio button
                    />
                  ))}
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Agregando" : "Agregar"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default New;
