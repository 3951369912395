import { Container, Row, Col, Form, Image } from "react-bootstrap";

const Template = ({ children, handleSubmit, onSubmit }) => {
  return (
    <Container>
      <Row className="justify-content-center align-items-center minh-100">
        <Col sm="11" md="10" lg="5" xl="4" xxl="4">
          <div className="card card-inverse p-4">
            <div className="card-block">
              <Image src="/images/logo.svg" fluid></Image>
              <hr />
              <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
                {children}
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Template;
