import styled from "styled-components";
import { BsList,BsPower } from "react-icons/bs";

const NavBar = ({logOut,showMenu, SetShowMenu }) => (
  <NavBarContent className="w-100">
    <button
      className={showMenu ? "buttonMenu" : "buttonMenu hiddenMenu"}
      onClick={() => SetShowMenu()}
    >
      <BsList />
    </button>
    <button
      className="buttonLogOut"
      onClick={() => {localStorage.removeItem("token_user"); logOut(null);}}
    >
      <BsPower />
    </button>
  </NavBarContent>
);

export default NavBar;

const NavBarContent = styled.div`
  height: 55px;
  background-color: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  .buttonMenu {
    position: absolute;
    left: 250px;
    transition: transform 1s ease;

    border: none;
    background: none;
    color: #000;
    font-size: 32px;
    cursor: pointer;
    padding: 0;
  }
  .buttonLogOut {
    position: absolute;
    right: 0px;
    transition: transform 1s ease;

    border: none;
    background: none;
    color: #000;
    font-size: 32px;
    cursor: pointer;
    padding: 0;
  }
`;
