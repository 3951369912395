import { Container, Row, Col } from "react-bootstrap";

const Home = ({ title_page }) => {
  document.title = title_page;

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>INICIO</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
