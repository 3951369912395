import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const View = ({ logOut, title_page }) => {
  document.title = title_page;
  const { id } = useParams();
  const [routes, setRoutes] = useState([]);

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  useEffect(() => {
    const url = "clients/routes_instance?instance_client=" + id;
    axios
      .get(process.env.REACT_APP_API + url)
      .then((response) => setRoutes(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
        <p>ID de la instancia: {id}</p>
        <p></p>
        <p>RUTAS:</p>
        {routes.map((route, index) => (
          <p key={index}>{route.route}</p>
        ))}
      </Row>
    </Container>
  );
};

export default View;
