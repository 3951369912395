import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Select from "react-select";
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const Edit = ({ logOut, title_page }) => {
  document.title = title_page;
  const [isps, setIsps] = useState([]);
  const [mikrotiks, setMikrotiks] = useState([]);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  const [isp, setIsp] = useState(-1);
  let history = useNavigate();
  const id_role = localStorage.getItem("id_role");
  const zoneztimes = [
    { value: 'America/Mexico_City', label: 'America/Mexico_City' },
    { value: 'America/Cancun', label: 'America/Cancun' },
    { value: 'America/Tijuana', label: 'America/Tijuana' },
    { value: 'America/Cancun', label: 'America/Cancun' },
  ];
  const [isActive, setIsActive] = useState(true);

  const handleChange = () => {
    setIsActive(!isActive);
    setValue("billable_isp", !isActive);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }, setValue
  } = useForm();

    
  useEffect(() => {
    // Establecer el valor predeterminado de billable_isp al inicio
    setValue("billable_isp", isActive);
  }, [isActive, setValue]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    console.log(parameters);
        setLoadingAdd(true);
        axios
          .post(process.env.REACT_APP_API + "isps", parameters)
          .then((response) => {
            console.log("Success", response);
            setModal({
              title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
              body: response.data.message,
              secondary: response.data.success ? "TERMINAR" : null,
              primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
            });
            openModal();
          })
          .catch((AxiosError) => {
            console.log(AxiosError);
            if (AxiosError.response.status === 404) {
              logOut();
            }
          })
          .finally(() => {
            setLoadingAdd(false);
          });
  };

  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

  const formattedNextMonth = nextMonth.toISOString().substr(0, 10);

  const [selectedServices, setSelectedServices] = useState(['1']);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedServices([...selectedServices, value]);
    } else {
      setSelectedServices(selectedServices.filter((service) => service !== value));
    }
  };

  const [selectedOption, setSelectedOption] = useState(zoneztimes[0]);
  // Función para manejar el cambio en el Select
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setValue('zonetime_isp', selectedOption); // Actualizar el valor en React Hook Form
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>EDITAR ISP</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>DATOS PERSONALES</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>NOMBRE DEL ISP:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name_isp")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_person">
                      <Form.Label>NOMBRE DEL CLIENTE:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name_person")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="lastname_person">
                      <Form.Label>APELLIDOS:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("lastname_person")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="phone_person">
                      <Form.Label>TELEFONO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("phone_person")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="zonetime_isp">
                      <Form.Label>ZONA HORARIA:</Form.Label>
                      <Select
                        options={zoneztimes}
                        isClearable={true}
                        isSearchable={true}
                        value={selectedOption}
                        onChange={handleSelectChange}
                      />

                      {/* Este input es invisible y se utiliza para almacenar el valor seleccionado */}
                      <input type="hidden" {...register('zonetime_isp', { value: selectedOption.value })} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mt-1">
              <Card.Header>FACTURACIÓN</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                      <Form.Label>Facturable:</Form.Label>
                      <Toggle
                        defaultChecked={isActive}
                        icons={false}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  {isActive && (
                    <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                      <Form.Group controlId="datepayment_isp">
                        <Form.Label>LIMITE DE PAGO:</Form.Label>
                        <Form.Control
                          type="date"
                          {...register("datepayment_isp")}
                          placeholder="Requerido"
                          required
                          defaultValue={formattedNextMonth}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="remidender_isp">
                      <Form.Label>RECORDATORIO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("reminder_isp")}
                        placeholder="Requerido"
                        required
                        defaultValue={0}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="services">
                      <Form.Label>SERVICIOS:</Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="checkbox-1"
                        label="VPN"
                        value="1"
                        onChange={handleCheckboxChange}
                        checked={selectedServices.includes('1')}
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox-2"
                        label="Sistema"
                        value="2"
                        onChange={handleCheckboxChange}
                        checked={selectedServices.includes('2')}
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox-3"
                        label="Hotspot"
                        value="3"
                        onChange={handleCheckboxChange}
                        checked={selectedServices.includes('4')}
                      />
                      <input type="hidden" {...register('servicesisps', { value: selectedServices })} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Agregando" : "Agregar"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Edit;
