import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import ListC from "../../../components/List";
import { FaPlus, FaEye, FaEdit, FaTrash } from "react-icons/fa"; // Importa los iconos de React Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const id_role = localStorage.getItem("id_role");
  let history = useNavigate();
  const module="isps";

  const columnFilter = [
    {
      value: "id_isp",
      text: "ID",
    },
    {
      value: "name_isp",
      text: "NOMBRE",
    },
    {
      value: "payment_isp",
      text: "FECHA DE PAGO",
    }
  ];

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_isp,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "NOMBRE",
      selector: (row) => row.name_isp,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "CLIENTE",
      selector: (row) => row.customer,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "TELEFONO",
      selector: (row) => row.phone_person,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "SERVICIOS",
      selector: (row) => row.services,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "DOMINIO",
      selector: (row) => row.domain_isp,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "LIMITE DE PAGO",
      selector: (row) => row.payment_isp,
      sortable: true,
      hide: "sm",
      wrap: true,
    },
    {
      name: "ESTADO",
      selector: (row) => (
        <Badge pill bg={row.status === 1 ? "success" : "danger"}>
          {row.status === 1 ? "ACTIVO" : "INACTIVO"}
        </Badge>
      ),
      hide: "sm",
      center: true,
      width: "120px",
    },
    {
      name: (
        <Form.Label
          className="font-weight-bold text-center"
          style={{ fontSize: "1rem", width: "100%" }}
        >
          ACCIONES
        </Form.Label>
      ),
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>VER</Tooltip>}>
            <button
              className="btn btn-link text-primary p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("view", row)}
            >
              <FaEye />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>EDITAR</Tooltip>}>
            <button
              className="btn btn-link text-warning p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("edit", row)}
            >
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>ELIMINAR</Tooltip>}>
            <button
              className="btn btn-link text-danger p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("delete", row)}
            >
              <FaTrash />
            </button>
          </OverlayTrigger>
        </div>
      ),
      button: "true",
    },
  ];

  const handleAction = (action, row) => {
    switch (action) {
      case "view":
        history(`/dashboard/isps/view/${row.id_isp}`); // Usa history.push en lugar de history
        break;
      case "edit":
        history(`/dashboard/isps/edit/${row.id_isp}`);
      case "delete":
        break;
      default:
        break;
    }
  };



  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>AGREGAR ISP</Tooltip>}
                >
                  <button
                    className="btn btn-link text-success btn-lg"
                    onClick={() => history(`/dashboard/isps/new`)}
                  >
                    <FaPlus />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>EDITAR ISPs</Tooltip>}
                >
                  <button
                    className="btn btn-link text-warning btn-lg" // Quitamos el margen y el relleno
                    onClick={() => history(`/dashboard/isps/edit`)}
                  >
                    <FaEdit />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>ELIMINAR ISPs</Tooltip>}
                >
                  <button
                    className="btn btn-link text-danger btn-lg" // Quitamos el margen y el relleno
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module + "/all"}
        columns={columns}
        defaultSortField={"id_isp"}
        columnFilter={columnFilter}
      ></ListC>
    </Container>
  );
};

export default List;
