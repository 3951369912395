import React, { useState, useEffect } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { Container, Row, Col, Form,Card,Button } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useLocation,NavLink } from 'react-router-dom';

const Setting = ({ logOut, title_page }) => {
  document.title = title_page;

  const [currentStep, setCurrentStep] = useState(0);
  const [ethers, setEthers] = useState([]);
  const location = useLocation();
  const { state } = location;
  const [isLoadingSubmit, setLoadingAdd] = useState(false);

  const mikrotik = state && state.rowData ? state.rowData : null;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const steps = [
    { number:1,title: "WAN"},
    { number:2,title: "WEB PROXY"},
    { number:3,title: "FIREWALL FILTER"},
    { number:4,title: "FIREWALL NAT"},
    { number:5,title: "FIREWALL LIST"},
    { number:6,title: "TODO ESTA CONFIGURADO CORRECTAMENTE"}
  ];

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "mikrotiks/getInterfacesM?id_mikrotik="+mikrotik.id_mikrotik)
      .then((response) => setEthers(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const onSubmit = (parameters) => {
    console.log(parameters);

    axios
      .post(process.env.REACT_APP_API + "mikrotiks/setWAN", parameters)
      .then((response) => {
        console.log("Success", response);
        
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      });
  };


  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
        <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Card>
            <Card.Header>Configuración</Card.Header>
            <Card.Body>
              <Row>
                <Col md="12">
                  <ProgressBar
                    percent={(currentStep / (steps.length - 1)) * 100}
                    filledBackground="linear-gradient(to right, #72fe85, #00af0f)"
                  >
                    {steps.map((step, index) => (
                      <Step key={index} transition="scale">
                        {({ accomplished }) => (
                          <div
                            className={`step ${
                              accomplished || index === currentStep
                                ? "completed"
                                : ""
                            }`}
                          >
                            {step.number}
                          </div>
                        )}
                      </Step>
                    ))}
                  </ProgressBar>
                </Col>
              </Row>
              <Row className="mt-3">
                <h2>{steps[currentStep].title}</h2>
              </Row>
              <Form.Control type="hidden" {...register('id_mikrotik')} value={mikrotik.id_mikrotik} />
              <Row xs={2} md={4} lg={5}>
                  {ethers.map((ether, index) => (
                    <Form.Check
                      key={ether.name}
                      type="checkbox" // Change the type to "radio"
                      label={ether.name}
                      id={`ether-checkbox-${ether.name}`}
                      className="mb-3"
                      name="ether_name" // Add a common name for radio button grouping
                      {...register("ether_name")}
                      value={ether.name} // Set the value for each radio button
                    />
                  ))}
                </Row>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Guardando" : "Siguiente"}
                </Button>
              </Card.Footer>
          </Card>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Setting;
