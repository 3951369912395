import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function PageNotFound() {
    return (
<Container>
      <Row className="justify-content-center align-items-center minh-100">
        <Col xs="10" sm="8" md="6" lg="4" xl="4" xxl="4">
            <h2 className="text-center">¡Opss! Pagina no encontrada o no existe</h2>
          <Image src={"/images/undraw_page_not_found_re_e9o6.svg"} fluid></Image>
        </Col>
      </Row>
    </Container>
    );
}

export default PageNotFound;