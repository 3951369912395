import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalCustom= ({modal,show,handleClose,action}) => {
  return (
    <Modal show={show} onHide={()=>handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modal.description}</Modal.Body>
      <Modal.Footer>
      {modal?.button_not_value!==null && (
      <Button variant="secondary" onClick={()=>action(modal.button_not_function)}>
      {modal.button_not_value}
    </Button>
    )}
        <Button variant="primary" onClick={()=>action(modal.button_yes_function)}>
          {modal.button_yes_value}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCustom;
