import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const New = ({ logOut, title_page }) => {
  document.title = title_page;
  const [isps, setIsps] = useState([]);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  const [isp, setIsp] = useState(-1);
  let history = useNavigate();
  const id_role = localStorage.getItem("id_role");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "isps")
      .then((response) => setIsps(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    console.log(parameters);

    setLoadingAdd(true);
    axios
      .post(process.env.REACT_APP_API + "mikrotiks", parameters)
      .then((response) => {
        console.log("Success", response);
        setModal({
          title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
          body: response.data.message,
          secondary: response.data.success ? "TERMINAR" : null,
          primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
        });
        openModal();
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const token = localStorage.getItem("token_user");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    setIsp(selectedValue);
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>Nuevo Mikrotik</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>Mikrotik</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="isp_mikrotik">
                      <Form.Label>ISP:</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("isp_mikrotik")}
                        onChange={handleSelectChange}
                      >
                        <option key="0" value="0">
                          {isps.length === 0
                            ? "Cargando..."
                            : "SELECCIONE UN ISP"}
                        </option>
                        {isps.map((option) => (
                          <option key={option.id_isp} value={option.id_isp}>
                            {option.name_isp}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.isp_mikrotik && (
                        <span style={{ color: "red" }}>
                          {errors.isp_zone.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_mikrotik">
                      <Form.Label>NOMBRE:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name_mikrotik")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="ip_mikrotik">
                      <Form.Label>IP:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("ip_mikrotik")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="port_mikrotik">
                      <Form.Label>PUERTO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("port_mikrotik")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="user_mikrotik">
                      <Form.Label>USUARIO:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("user_mikrotik")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="password_mikrotik">
                      <Form.Label>CONTRASEÑA:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("password_mikrotik")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                  <Form.Group controlId="notification_mikrotik">
                      <Form.Label>NOTIFICACION VPN:</Form.Label>
                      <Form.Check
                        type="checkbox"
                        {...register("notification_mikrotik")}
                      />
                      <Form.Text>
                        Marca esta casilla para enviar notificaciones de conexion OpenVPN.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                  <Form.Group controlId="route_mikrotik">
                      <Form.Label>NOTIFICACION RUTA:</Form.Label>
                      <Form.Check
                        type="checkbox"
                        {...register("route_mikrotik")}
                      />
                      <Form.Text>
                        Marca esta casilla para enviar la ruta de la WAN.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Agregando" : "Agregar"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default New;
