import {InputGroup,Form} from "react-bootstrap";

const Password = ({children, name,place,register, response,autoComplete=""}) => {
  return (
    <InputGroup className="mt-2" hasValidation>
        <Form.Control
          type="password"
          {...register(name)}
          placeholder={place}
          autoComplete={autoComplete}
          required
          isInvalid={(response?.code === 293 || response?.code === 199)}
          isValid={response === null && response.code !== 193}
        />
        {children}
        {(response?.code === 293 || response?.code === 199) && (
          <Form.Control.Feedback type="invalid">
            {response.description}
          </Form.Control.Feedback>
        )}
      </InputGroup>
  );
};

export default Password;
