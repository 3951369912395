import { Image } from "react-bootstrap";
import { NavLink  } from "react-router-dom";
import styled from "styled-components";
import { BsFillHouseDoorFill,BsFillPeopleFill,BsFillRouterFill,BsFillMapFill,BsFillPersonLinesFill,BsClipboard2Fill} from "react-icons/bs";

const SideBar = () => {
  const name_user=localStorage.getItem("name_user");
  const name_role=localStorage.getItem("name_role")
  return (
  <SidbarContent>
    <div className="divLogo ps-4 pt-2 pb-2 d-flex align-items-center">
      <Image src="/images/logo_cut.svg" roundedCircle />
      <span className="ps-2">TEAMISP</span>
    </div>
    <div className="divUser  ps-4 pt-3 pb-3 d-flex align-items-center">
      <Image src="/images/avatar.png" roundedCircle />
      <div className="ps-2">
        <p>{name_user}</p>
        <p>{name_role}</p>
      </div>
    </div>
    <div className="divMenu p-2">
      <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'}  to="/dashboard/home">
        <BsFillHouseDoorFill className="iconItem"/><span className="ps-1">INICIO</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'}  to="/dashboard/customers">
        <BsFillPeopleFill className="iconItem"/><span className="ps-1">CLIENTES</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'}  to="/dashboard/packages">
        <BsClipboard2Fill className="iconItem"/><span className="ps-1">PAQUETES</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'} to="/dashboard/mikrotiks">
        <BsFillRouterFill className="iconItem"/><span className="ps-1">MIKROTIKS</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'} to="/dashboard/users">
        <BsFillPeopleFill className="iconItem"/><span className="ps-1">USUARIOS</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'} to="/dashboard/zones">
        <BsFillMapFill className="iconItem"/><span className="ps-1">ZONAS</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'} to="/dashboard/isps">
        <BsFillPersonLinesFill className="iconItem"/><span className="ps-1">ISPs</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'} to="/dashboard/instances">
        <BsFillPersonLinesFill className="iconItem"/><span className="ps-1">INSTANCIAS</span>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? 'linKItem d-flex align-items-center active' : 'linKItem d-flex align-items-center'} to="/dashboard/clients">
        <BsFillPersonLinesFill className="iconItem"/><span className="ps-1">CLIENTES</span>
        </NavLink>
    </div>
  </SidbarContent>
);};

export default SideBar;

const SidbarContent = styled.div`
  background-color: #343a40;
  color: #c2c7d0;
  width: 250px;
  height: 100%;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.4);

  .divLogo {
    border-top: 1px solid #4b545c;
    img {
      width: 35px;
      height: 35px;
    }
    span {
      font-size: 18px; /* Cambia el tamaño de letra a 18 píxeles */
      font-weight: 450;
    }
  }

  .divUser {
    border-top: 1px solid #4b545c;
    width: 100%;
    img {
      width: 35px;
      height: 35px;
    }
    
    p {
      font-size: 15px; /* Cambia el tamaño de letra a 18 píxeles */
      font-weight: 450;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }
  .divMenu {
    border-top: 1px solid #4b545c;

    .linKItem{
      text-decoration: none;
      width: 100%;
      color: #c2c7d0;
      padding: 10px 20px 10px 20px;
      border-radius: 5px;
    }
.iconItem{
  font-size: 24px;
}
    .active{
      background-color: #007BFF;
      color:white;
    }
  }
`;
