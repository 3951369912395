import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const New = ({ logOut, title_page }) => {
  document.title = title_page;
  const [isps, setIsps] = useState([]);
  const [roles, setRoles] = useState([]);
  const [mikrotiks, setMikrotiks] = useState([]);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  const [isp, setIsp] = useState(-1);
  let history = useNavigate();
  const id_role = localStorage.getItem("id_role");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "isps")
      .then((response) => setIsps(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });

      axios
      .get(process.env.REACT_APP_API + "roles")
      .then((response) => setRoles(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    console.log(parameters);

    setLoadingAdd(true);
    axios
      .post(process.env.REACT_APP_API + "users", parameters)
      .then((response) => {
        console.log("Success", response);
        setModal({
          title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
          body: response.data.message,
          secondary: response.data.success ? "TERMINAR" : null,
          primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
        });
        openModal();
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
      
  };

  const token = localStorage.getItem("token_user");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    setIsp(selectedValue);

    axios
      .get(process.env.REACT_APP_API + "mikrotiks?isp="+selectedValue, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMikrotiks(response.data.data);
      })
      .catch((AxiosError) => {
        if (AxiosError.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          console.log(AxiosError.response.status);

          if (AxiosError.response.status === 401) {
            logOut();
          }
        } else if (AxiosError.request) {
          alert(
            "No se ha recibido una respuesta del servidor. Si el problema persiste contacte al administrador."
          );
          // La solicitud fue realizada pero no se recibió ninguna respuesta
          console.log(AxiosError.request);
        } else {
          // Ocurrió un error durante la solicitud
          console.log("Error", AxiosError.message);
        }
      })
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>Nuevo Usuario</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>Usuario</Card.Header>
              <Card.Body>
                <Row>
                  {id_role == 2 && (
                    <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                      <Form.Group controlId="isp_user">
                        <Form.Label>ISP:</Form.Label>
                        <Form.Control
                          as="select"
                          {...register("isp_user")}
                          onChange={handleSelectChange}
                        >
                          <option key="0" value="0">
                            {isps.length === 0
                              ? "Cargando..."
                              : "SELECCIONE UN ISP"}
                          </option>
                          {isps.map((option) => (
                            <option key={option.id_isp} value={option.id_isp}>
                              {option.name_isp}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.isp_user && (
                          <span style={{ color: "red" }}>
                            {errors.isp_user.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_person">
                      <Form.Label>NOMBRE:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name_person")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="lastname_person">
                      <Form.Label>APELLIDOS:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("lastname_person")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="4" sm="4" md="2" lg="2" xl="1" xxl="1">
                    <Form.Group controlId="countrycode_person">
                      <Form.Label>CODIGO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("countrycode_person")}
                        placeholder="Requerido"
                        defaultValue={521}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="8" sm="8" md="4" lg="2" xl="2" xxl="2">
                    <Form.Group controlId="phone_person">
                      <Form.Label>TELEFONO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("phone_person")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="email_user">
                      <Form.Label>CORREO:</Form.Label>
                      <Form.Control
                        type="email"
                        {...register("email_user")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                    <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                      <Form.Group controlId="id_role">
                        <Form.Label>ROL:</Form.Label>
                        <Form.Control
                          as="select"
                          {...register("role_user")}
                          onChange={handleSelectChange}
                        >
                          <option key="0" value="0">
                            {roles.length === 0
                              ? "Cargando..."
                              : "SELECCIONE UN ROL"}
                          </option>
                          {roles.map((option) => (
                            <option key={option.id_role} value={option.id_role}>
                              {option.name_role}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.role_user && (
                          <span style={{ color: "red" }}>
                            {errors.role_user.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="password_user">
                      <Form.Label>Contraseña:</Form.Label>
                      <Form.Control
                        type="password"
                        {...register("password_user")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="rpassword_user">
                      <Form.Label>Repetir Contraseña:</Form.Label>
                      <Form.Control
                        type="password"
                        {...register("rpassword_user")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Agregando" : "Agregar"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default New;
