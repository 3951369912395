import {InputGroup,Form} from "react-bootstrap";

const Email = ({children, register, response,type="email",value=null}) => {
  return (
    <InputGroup className="mt-2" hasValidation>
    <Form.Control
      type={type}
      {...register("email_user")}
      placeholder="Escriba su correo"
      defaultValue={value==null ? "" : value}
      required
      isInvalid={(response?.code === 294 || response?.code === 295 || response?.code === 297)}
      isValid={response === null && (response?.code === 294 || response?.code === 295 || response?.code === 297)}
    />
    {children}
    {(response?.code === 294 || response?.code === 295 || response?.code === 297) && (
      <Form.Control.Feedback type="invalid">
        {response.description}
      </Form.Control.Feedback>
    )}
  </InputGroup>
  );
};

export default Email;
