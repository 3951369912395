import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Template from "../components/Template";
import Email from "../components/form/Email";
import Password from "../components/form/Password";
import ButtonCustom from "../components/form/ButtonCustom";
import LinkCustom from "../components/form/LinkCustom";
import ModalCustom from "../components/ModalCustom";
import axios from "axios";

const Login = ({ setToken_user, title_page }) => {
  document.title = title_page;
  const { register, handleSubmit } = useForm();
  const [response, setResponse] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let history = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (response) => {
    setResponse(response);
    if (response?.button_yes_value !== null) setShow(true);
    console.log(response);
  };
  const action = (path) => {
    if (path === undefined) {
      handleClose();
    } else {
      history("/" + path);
    }
  };
  const onSubmit = (parameters) => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API + "users/login", parameters)
      .then((response) => {
        console.log("Success", response);
        if (response.data.success) {
          localStorage.setItem("token_user", response.data.token_user);
          setToken_user(response.data.token_user);
          history("/dashboard/home");
        } else {
          alert(response.data.message);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.status !== undefined){
          console.log(AxiosError.status, AxiosError.statusText);
        }else {
          console.log(AxiosError.code, AxiosError.message);
          handleShow({"title":"ERROR","description":AxiosError.message+". Contacte con el administrador","button_yes_value":"ACEPTAR","button_not_value":null});
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Template handleSubmit={handleSubmit} onSubmit={onSubmit}>
      <Email register={register} response={response} />
      <Password
        name="password_user"
        place="Escriba su contraseña"
        register={register}
        response={response}
      />
      <ButtonCustom
        isLoading={isLoading}
        typ="submit"
        text_loading="Iniciando sesión…"
        text="Iniciar sesión"
      />
      <LinkCustom text="Recuperar Contraseña" topage="recoverypassword" />
      <LinkCustom
        paragraph="¿No tienes una cuenta? "
        text="Regístrate aquí"
        topage="register"
      />
      <ModalCustom
        modal={response}
        show={show}
        handleClose={handleClose}
        action={response?.button_yes_value !== null ? action : handleClose}
      />
    </Template>
  );
};

export default Login;
