import { Container, Row, Col, Card} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ListC from "../../../components/List";
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Importa los iconos de React Icons
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const id_role = localStorage.getItem("id_role");
  const module="zones";

  const columns = [
    {
      name: "NOMBRE",
      selector: (row) => row.name_zone,
    },
    {
      name: "MIKROTIK",
      selector: (row) => row.name_mikrotik,
    },
    {
      name: "Código Postal",
      selector: (row) => row.cp_zone,
    },
    {
      name: 'Acciones',
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Ver</Tooltip>}
          >
            <button
              className="btn btn-link text-primary mr-2"
              onClick={() => handleAction('view', row)}
            >
              <FaEye />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              className="btn btn-link text-warning mr-2"
              onClick={() => handleAction('edit', row)}
            >
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Eliminar</Tooltip>}
          >
            <button
              className="btn btn-link text-danger"
              onClick={() => handleAction('delete', row)}
            >
              <FaTrash />
            </button>
          </OverlayTrigger>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: '130px',
    },
  ];

  const handleAction = (action, row) => {
    switch (action) {
      case 'view':
        // Lógica para ver el elemento
        break;
      case 'edit':
        // Lógica para editar el elemento
        break;
      case 'delete':
        // Lógica para eliminar el elemento
        break;
      default:
        break;
    }
  };

  const columnsSuperAdministrador = [
    {
      name: "ID",
      selector: (row) => row.id_zone,
    },
    {
      name: "NOMBRE ISP",
      selector: (row) => row.name_isp,
    }
  ];

  if (id_role == 2) {
    columns.unshift(...columnsSuperAdministrador);
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Acciones</Card.Header>
            <Card.Body>
              <NavLink
                className="btn btn-primary"
                to={"/dashboard/" + module + "/new"}
              >
                Nuevo
              </NavLink>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module}
        columns={columns}
        defaultSortField={"name_mikrotik"}
      ></ListC>
    </Container>
  );
};

export default List;
