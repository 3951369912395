import { Button } from "react-bootstrap";

const ButtonCustom = ({ isLoading, typ = "submit", text_loading, text="button" }) => {
  return (
    <Button type={typ} className="w-100 mt-2 custom-btn" disabled={isLoading}>
      {isLoading ? text_loading : text}
    </Button>
  );
};

export default ButtonCustom;
