import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Select from "react-select";
import Toggle from 'react-toggle';
import 'react-toggle/style.css';


const New = ({ logOut, title_page }) => {
  document.title = title_page;
  const [countries, setCountries] = useState([]);
  const [mikrotiks, setMikrotiks] = useState([]);
  const [states, setStates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [suburbs, setSuburbs] = useState([]);
  const [packages, setPackages] = useState([]);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  let history = useNavigate();

  const type_device = [
    { value: 1, label: 'ESTATICO' },
    { value: 2, label: 'DHCP' },
  ];

  const [isActive, setIsActive] = useState(true);

  const handleChange = () => {
    setIsActive(!isActive);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }, setValue
  } = useForm();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "countries/select")
      .then((response) => setCountries(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });

    axios
      .get(process.env.REACT_APP_API + "mikrotiks/select")
      .then((response) => setMikrotiks(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });

    axios
      .get(process.env.REACT_APP_API + "packages/select")
      .then((response) => setPackages(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    console.log(parameters);
    /*
        setLoadingAdd(true);
        axios
          .post(process.env.REACT_APP_API + "clients", parameters)
          .then((response) => {
            console.log("Success", response);
            setModal({
              title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
              body: response.data.message,
              secondary: response.data.success ? "TERMINAR" : null,
              primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
            });
            openModal();
          })
          .catch((AxiosError) => {
            console.log(AxiosError);
            if (AxiosError.response.status === 404) {
              logOut();
            }
          })
          .finally(() => {
            setLoadingAdd(false);
          });
          */
  };

  const handleChangeCountries = selectedOption => {
    if (selectedOption === null) {
      setStates([]);
      setMunicipalities([]);
      setSuburbs([]);
    } else {
      axios
        .get(process.env.REACT_APP_API + "states/select?country_state=" + selectedOption.value)
        .then((response) => setStates(response.data.data))
        .catch((AxiosError) => {
          console.log(AxiosError);
          if (AxiosError.response.status === 401) {
            logOut();
          }
        });
    }
    setSelectedOptionCountry(selectedOption);
  };

  const handleChangeStates = selectedOption => {
    if (selectedOption === null) {
      setMunicipalities([]);
      setSuburbs([]);
    } else {
      axios
        .get(process.env.REACT_APP_API + "municipalities/select?state_municipality=" + selectedOption.value)
        .then((response) => setMunicipalities(response.data.data))
        .catch((AxiosError) => {
          console.log(AxiosError);
          if (AxiosError.response.status === 401) {
            logOut();
          }
        });
    }
    setSelectedOptionEstate(selectedOption);
  };

  const handleChangeMunicipalities = selectedOption => {
    if (selectedOption === null) {
      setSuburbs([]);
    } else {
      axios
        .get(process.env.REACT_APP_API + "suburbs/select?municipality_suburb=" + selectedOption.value)
        .then((response) => setSuburbs(response.data.data))
        .catch((AxiosError) => {
          console.log(AxiosError);
          if (AxiosError.response.status === 401) {
            logOut();
          }
        });
    }
    setSelectedOptionMunicipality(selectedOption);
  };

  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

  const formattedNextMonth = nextMonth.toISOString().substr(0, 10);

  const [selectedOptionCountry, setSelectedOptionCountry] = useState(0);
  const [selectedOptionEstate, setSelectedOptionEstate] = useState(0);
  const [selectedOptionMunicipality, setSelectedOptionMunicipality] = useState(0);
  const [selectedOptionSuburb, setSelectedOptionSuburb] = useState(0);
  const [selectedOptionPackage, setSelectedOptionPackage] = useState(0);
  const [selectedOptionMikrotik, setSelectedOptionMikrotik] = useState(0);
  const [selectedOptionType, setSelectedOptionType] = useState(0);

  const handleSelectChangeSuburb = (selectedOption) => {
    setSelectedOptionSuburb(selectedOption);
  };
  const handleSelectChangePackage = (selectedOption) => {
    setSelectedOptionPackage(selectedOption);
  };
  const handleSelectChangeMikrotik = (selectedOption) => {
    setSelectedOptionMikrotik(selectedOption);
  };
  const handleSelectChangeType = (selectedOption) => {
    setSelectedOptionType(selectedOption);
  };

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          <h3>NUEVO CLIENTE</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>DATOS PERSONALES</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>NOMBRE:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name_persone")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>APELLIDOS:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("lastname_person")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>TELEFONO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("phone_person")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mt-1">
              <Card.Header>DIRECCION</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>PAIS:</Form.Label>
                      <Select
                        onChange={handleChangeCountries}
                        options={countries}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>ESTADO:</Form.Label>
                      <Select
                        onChange={handleChangeStates}
                        options={states}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>MUNICIPIO:</Form.Label>
                      <Select
                        onChange={handleChangeMunicipalities}
                        options={municipalities}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>ZONA:</Form.Label>
                      <Select
                        onChange={handleSelectChangeSuburb}
                        options={suburbs}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>CALLE:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("street_address")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>NUMERO:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("number_address")}
                        placeholder="Opcional"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mt-1">
              <Card.Header>FACTURACIÒN</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                      <Form.Label>Facturable:</Form.Label>
                      <Toggle
                        defaultChecked={isActive}
                        icons={false}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  {isActive && (
                    <>
                      <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                        <Form.Group controlId="name_isp">
                          <Form.Label>PAQUETE:</Form.Label>
                          <Select
                            onChange={handleSelectChangePackage}
                            options={packages}
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Selecciona"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                        <Form.Group controlId="name_isp">
                          <Form.Label>LIMITE DE PAGO:</Form.Label>
                          <Form.Control
                            type="date"
                            {...register("datepayment_isp")}
                            placeholder="Requerido"
                            required
                            defaultValue={formattedNextMonth}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Card className="mt-1">
              <Card.Header>DATOS TECNICOS</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="number_customer">
                      <Form.Label>NUMERO DE CLIENTE:</Form.Label>
                      <Form.Control
                        type="number"
                        {...register("number_customer")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>MIKROTIK:</Form.Label>
                      <Select
                        onChange={handleSelectChangeMikrotik}
                        options={mikrotiks}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="name_isp">
                      <Form.Label>TIPO:</Form.Label>
                      <Select
                        onChange={handleSelectChangeType}
                        options={type_device}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                      <Form.Label>DINAMICA:</Form.Label>
                      <Toggle
                        defaultChecked={isActive}
                        icons={false}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="mac_device">
                      <Form.Label>MAC:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("mac_device")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="ip_device">
                      <Form.Label>IP:</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("ip_device")}
                        placeholder="Requerido"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Agregando" : "Agregar"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default New;
