import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import ListC from "../../../components/List";
import { FaPlus, FaEye, FaEdit, FaTrash } from "react-icons/fa"; // Importa los iconos de React Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const id_role = localStorage.getItem("id_role");
  const module="instances";
  let history = useNavigate();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_instance,
      sortable: true,
    },
    {
      name: "PUERTO",
      selector: (row) => row.port_instance,
      sortable: true,
    },
    {
      name: "NETWORK",
      selector: (row) => row.network_instance,
      sortable: true,
    },
    {
      name: "CREADO",
      selector: (row) => row.created_instance,
      sortable: true,
    },
    {
      name: "ACTUALIZADO",
      selector: (row) => row.updated_instance,
      sortable: true,
    },
    {
      name: (
        <Form.Label
          className="font-weight-bold text-center"
          style={{ fontSize: "1rem", width: "100%" }}
        >
          ACCIONES
        </Form.Label>
      ),
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>VER</Tooltip>}>
            <button
              className="btn btn-link text-primary p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("view", row)}
            >
              <FaEye />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>EDITAR</Tooltip>}>
            <button
              className="btn btn-link text-warning p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("edit", row)}
            >
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>ELIMINAR</Tooltip>}>
            <button
              className="btn btn-link text-danger p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("delete", row)}
            >
              <FaTrash />
            </button>
          </OverlayTrigger>
        </div>
      ),
      button: "true",
    },
  ];

  const columnFilter = [
    {
      value: "id_instance",
      text: "ID",
    },
    {
      value: "port_instance",
      text: "IP",
    },
    {
      value: "network_instance",
      text: "NETWORK",
    },
    {
      value: "datecreate_instance",
      text: "CREADO",
    },
    {
      value: "dateupdate_instance",
      text: "ACTUALIZADO",
    }
  ];

  const handleAction = (action, row) => {
    switch (action) {
      case "view":
        history(`/dashboard/instances/view/${row.id_instance}`); // Usa history.push en lugar de history
        break;
      case "edit":
      case "delete":
        break;
      default:
        break;
    }
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>AGREGAR CLIENTE</Tooltip>}
                >
                  <button
                    className="btn btn-link text-success btn-lg"
                    onClick={() => history(`/dashboard/instances/new`)}
                  >
                    <FaPlus />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>EDITAR CLIENTES</Tooltip>}
                >
                  <button
                    className="btn btn-link text-warning btn-lg" // Quitamos el margen y el relleno
                  >
                    <FaEdit />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>ELIMINAR CLIENTES</Tooltip>}
                >
                  <button
                    className="btn btn-link text-danger btn-lg" // Quitamos el margen y el relleno
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module + "/all"}
        columns={columns}
        defaultSortField={"id_instance"}
        columnFilter={columnFilter}
      ></ListC>
    </Container>
  );
};

export default List;
