import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Container,Row, Col, Form } from "react-bootstrap";
import { FaUndo } from "react-icons/fa"; // Importa los iconos de React Icons
import { BsX } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIsMobile } from "../provider/MobileProvider";

const List = ({
  logOut,
  title_page,
  url,
  columns,
  defaultSortField,
  columnFilter,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { isMobile } = useIsMobile();

  const token = localStorage.getItem("token_user");

  const fetchData = async () => {
    axios
      .get(process.env.REACT_APP_API + url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((AxiosError) => {
        // ... (manejo de errores)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CustomNoDataComponent = () => {
    return <div>Cargando registros...</div>;
  };

  const [filterText, setFilterText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(columnFilter[0].value);

  const filteredData = data.filter((item) =>
    (item[selectedColumn] + "").toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleClearFilter = () => {
    setFilterText("");
  };

  const ExpandedComponent = ({ data }) => {
    columns.forEach((column) => {
      if (column.hide !== undefined) {
        console.log(column.selector(data));
      }
    });
    return (
      <Container>
      {columns.map((column) =>
        column.hide !== undefined ? (
          <Row key={column.name}>
            <Col xs={3} style={{ fontWeight: 'bold' }}>
              {column.name}:
            </Col>
            <Col xs={9}>
              {typeof column.selector === "function" ? column.selector(data) : ""}
            </Col>
          </Row>
        ) : null
      )}
    </Container>
    );
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <Row>
        <Col xs="auto" className="p-1">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Actualizar Tabla</Tooltip>}
          >
            <button
              className="btn btn-link text-black px-0 btn-sm" // Quitamos el margen y el relleno
              onClick={fetchData}
            >
              <FaUndo />
            </button>
          </OverlayTrigger>
        </Col>
        <Col xs="4" className="p-1">
          <Form.Group>
            <Form.Control
              as="select"
              onChange={(e) => setSelectedColumn(e.target.value)}
              value={selectedColumn}
            >
              {columnFilter.map((column) => (
                <option key={column.value} value={column.value}>
                  {column.text}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs="7" className="p-1">
          <Form.Group>
            <div className="input-group">
              <Form.Control
                type="text"
                placeholder="Filtrar"
                value={filterText}
                onChange={handleFilterChange}
              />
              {filterText && (
                <div className="input-group-append">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>LIMPIAR FILTRO</Tooltip>}
                  >
                    <button
                      className="btn btn-link text-black"
                      type="button"
                      onClick={handleClearFilter}
                    >
                      <BsX />
                    </button>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          </Form.Group>
        </Col>
      </Row>
    );
  }, [filterText, selectedColumn]);

  return (
    <DataTable
      keyField="id_client" // Asegúrate de proporcionar una clave única en tu conjunto de datos
      data={filteredData}
      columns={columns} // Usar las columnas modificadas
      striped
      highlightOnHover
      responsive
      defaultSortField={defaultSortField}
      noDataComponent={
        isLoading ? <CustomNoDataComponent /> : "No hay registros para mostrar"
      }
      pagination
      selectableRows
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      expandableRows={isMobile}
      expandableRowsComponent={ExpandedComponent}
    />
  );
};

export default List;
