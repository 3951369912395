import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import ListC from "../../../components/List";
import { FaPlus, FaEye, FaEdit, FaTrash, FaFileDownload } from "react-icons/fa"; // Importa los iconos de React Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const module = "packages";
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  const [id_client, setId_client] = useState(0);

  let history = useNavigate();

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmitDelete = () => {
    axios
      .delete(process.env.REACT_APP_API + "clients?id_client=" + id_client)
      .then((response) => {
        console.log(response);
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      })
      .finally(() => {
        closeModal();
      });
  };

  const columnFilter = [
    {
      value: "id_package",
      text: "ID",
    },
    {
      value: "name_isp",
      text: "ISP",
    },
    {
      value: "name_package",
      text: "NOMBRE",
    },
    {
      value: "download_package",
      text: "DESCARGA",
    },
    {
      value: "upload_package",
      text: "SUBIDA",
    },
    {
      value: "pricemonth_package",
      text: "PRECIO",
    },
    {
      value: "connected_client",
      text: "APLICABLE",
    },
  ];

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_package,
      sortable: true,
      hide: "sm",
      width: "60px",
    },
    {
      name: "ISP",
      selector: (row) => row.name_isp,
      sortable: true,
      wrap: true,
    },
    {
      name: "NOMBRE",
      selector: (row) => row.name_package,
      sortable: true,
      hide: "sm",
      width: "110px",
    },
    {
      name: "DESCARGA",
      selector: (row) => row.download_package,
      sortable: true,
      wrap: true,
    },
    {
      name: "SUBIDA",
      selector: (row) => row.upload_package,
      sortable: true,
      width: "100px",
      hide: "sm",
    },
    {
      name: "PRECIO",
      selector: (row) => row.pricemonth_package,
      sortable: true,
      wrap: true,
      hide: "sm",
    },
    {
      name: "APLICABLE",
      selector: "",
      hide: "sm",
      center: true,
      width: "120px",
    },
    {
      name: "ACCIONES",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>VER</Tooltip>}>
            <button
              className="btn btn-link text-primary p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("view", row)}
            >
              <FaEye />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>EDITAR</Tooltip>}>
            <button
              className="btn btn-link text-warning p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("edit", row)}
            >
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>ELIMINAR</Tooltip>}>
            <button
              className="btn btn-link text-danger p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("delete", row)}
            >
              <FaTrash />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>DESCARGAR ARCHIVO</Tooltip>}
          >
            <button
              className="btn btn-link text-success p-1" // Quitamos el margen y el relleno
              onClick={() => downloadFile(row)}
            >
              <FaFileDownload />
            </button>
          </OverlayTrigger>
        </div>
      ),
      button: "true",
      width: "120px",
      hide: "sm",
    },
  ];

  const downloadFile = (row) => {
    axios({
      url:
        process.env.REACT_APP_API +
        "clients/download_ovpn?id_client=" +
        row.id_client,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        if (row.typename_client == 3) {
          link.setAttribute("download", row.commonname_client + ".zip");
        } else if (row.typename_client == 1) {
          link.setAttribute(
            "download",
            "server_" + row.commonname_client + ".conf"
          );
        } else if (row.typename_client == 2) {
          link.setAttribute(
            "download",
            "user_" + row.commonname_client + ".ovpn"
          );
        } else if (row.typename_client == 4) {
          link.setAttribute(
            "download",
            "admin_" + row.commonname_client + ".ovpn"
          );
        } else {
          link.setAttribute("download", row.commonname_client + ".ovpn");
        }
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  const handleAction = (action, row) => {
    switch (action) {
      case "view":
        history(`/dashboard/clients/view/${row.id_client}`); // Usa history.push en lugar de history
        break;
      case "edit":
        history(`/dashboard/clients/edit/${row.id_client}`); // Usa history.push en lugar de history
        break;
      case "delete":
        setId_client(row.id_client);
        setModal({
          title: "¡ADVERTENCIA!",
          body: `¿Está seguro de que desea proceder con la eliminación de este cliente?`,
          danger: "ELIMINAR",
          secondary: "CANCELAR",
        });
        openModal();
        break;
      default:
        break;
    }
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>AGREGAR CLIENTE</Tooltip>}
                >
                  <button
                    className="btn btn-link text-success btn-lg"
                    onClick={() => history(`/dashboard/clients/new`)}
                  >
                    <FaPlus />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>EDITAR CLIENTES</Tooltip>}
                >
                  <button
                    className="btn btn-link text-warning btn-lg" // Quitamos el margen y el relleno
                  >
                    <FaEdit />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>ELIMINAR CLIENTES</Tooltip>}
                >
                  <button
                    className="btn btn-link text-danger btn-lg" // Quitamos el margen y el relleno
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module + "/all"}
        columns={columns}
        defaultSortField={"id_client"}
        columnFilter={columnFilter}
      ></ListC>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={closeModal}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="danger"
            onClick={
              modal?.title === "¡ADVERTENCIA!" ? onSubmitDelete : closeModal
            }
          >
            {modal?.danger}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default List;
