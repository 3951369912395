function Spinner() {
  return (
    <div className="minh-100 center">
      <div className="spinner">
        <img className="spinnerima" src="/images/logo_cut.svg" alt="Logo cut"/>
      </div>
    </div>
  );
}

export default Spinner;
