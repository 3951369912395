import { Container, Row, Col, Card,Badge} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ListC from "../../../components/List";

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const id_role = localStorage.getItem("id_role");
  const module="addresses";

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_address,
    },
    {
      name: "MIKROTIK",
      selector: (row) => row.name_mikrotik,
    },
    {
      name: "IP",
      selector: (row) => row.ip_address,
    },
    {
      name: "MASK",
      selector: (row) => row.mask_address,
    }
  ];

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Acciones</Card.Header>
            <Card.Body>
              <NavLink
                className="btn btn-primary"
                to={"/dashboard/" + module + "/new"}
              >
                Nuevo
              </NavLink>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module}
        columns={columns}
        defaultSortField={"id_client"}
      ></ListC>
    </Container>
  );
};

export default List;
