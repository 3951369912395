import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {MobileProvider} from "./provider/MobileProvider";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import DashBoard from "./routes/DashBoard";
import { useState } from "react";

function App() {
  const [token_user, setToken_user] = useState(
    localStorage.getItem("token_user")
  );

  const logOut = () => {
    localStorage.removeItem("token_user");
    setToken_user(null);
  };

  return (
    <MobileProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Login
                setToken_user={setToken_user}
                title_page="INICIAR SESIÓN"
              />
            }
          />
          <Route
            path="/dashboard/*"
            element={
              token_user ? <DashBoard logOut={logOut} /> : <Navigate to="/" />
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </MobileProvider>
  );
}

export default App;
