import { Container, Row, Col, Card} from "react-bootstrap";
import { FaPlus, FaEye, FaEdit, FaTrash } from "react-icons/fa"; // Importa los iconos de React Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ListC from "../../../components/List";

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const id_role = localStorage.getItem("id_role");
  const module="users";

  const columnFilter = [
    {
      value: "id_user",
      text: "ID",
    }
  ];

  const columns = [
    {
      name: "CORREO",
      selector: (row) => row.email_user,
    }
    ,
    {
      name: "ROL",
      selector: (row) => row.name_role,
    },
    {
      name: "ACCIONES",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>VER</Tooltip>}>
            <button
              className="btn btn-link text-primary p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("view", row)}
            >
              <FaEye />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>EDITAR</Tooltip>}>
            <button
              className="btn btn-link text-warning p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("edit", row)}
            >
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>ELIMINAR</Tooltip>}>
            <button
              className="btn btn-link text-danger p-1" // Quitamos el margen y el relleno
              onClick={() => handleAction("delete", row)}
            >
              <FaTrash />
            </button>
          </OverlayTrigger>
        </div>
      ),
      button: "true",
      width: "120px",
      hide: "sm",
    },
  ];

  const columnsSuperAdministrador = [
    {
      name: "ID USER",
      selector: (row) => row.id_user,
    },
    {
      name: "NOMBRE ISP",
      selector: (row) => row.name_isp,
    }
  ];

  const handleAction = (action, row) => {
    
  };

  if (id_role == 2) {
    columns.unshift(...columnsSuperAdministrador);
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Acciones</Card.Header>
            <Card.Body>
              <NavLink
                className="btn btn-primary"
                to={"/dashboard/" + module + "/new"}
              >
                Nuevo
              </NavLink>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module + "/all"}
        columns={columns}
        defaultSortField={"id_user"}
        columnFilter={columnFilter}
      ></ListC>
    </Container>
  );
};

export default List;
