import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ListC from "../../../components/List";
import { FaEye, FaEdit, FaTrash, FaCog } from "react-icons/fa"; // Importa los iconos de React Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const List = ({ logOut, title_page }) => {
  document.title = title_page;
  const id_role = localStorage.getItem("id_role");
  const module = "mikrotiks";
  let history = useNavigate();

  const columns = [
    {
      name: "NOMBRE",
      selector: (row) => row.name_mikrotik,
      sortable: true,
      wrap: true,
    },
    {
      name: "IP",
      selector: (row) => row.ip_mikrotik,
      sortable: true,
      width: "200px",
    },
    {
      name: "ESTADO",
      selector: (row) => (
        <Badge
          pill
          bg={
            row.connected_mikrotik === 1
              ? "success"
              : row.connected_mikrotik === 0
              ? "danger"
              : "secondary"
          }
        >
          {row.connected_mikrotik === 1
            ? "CONECTADO"
            : row.connected_mikrotik === 0
            ? "DESCONECTADO"
            : "NO CONFIGURADO"}
        </Badge>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Acciones",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>Ver</Tooltip>}>
            <button
              className="btn btn-link text-primary p-1"
              onClick={() => handleAction("view", row)}
            >
              <FaEye />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Editar</Tooltip>}>
            <button
              className="btn btn-link text-warning p-1"
              onClick={() => handleAction("edit", row)}
            >
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Eliminar</Tooltip>}>
            <button
              className="btn btn-link text-danger p-1"
              onClick={() => handleAction("delete", row)}
            >
              <FaTrash />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Configuración</Tooltip>}
          >
            <button
              className="btn btn-link text-secondary p-1"
              onClick={() => handleAction("setting", row)}
            >
              <FaCog />
            </button>
          </OverlayTrigger>
        </div>
      ),
      button: "true",
      width: "120px",
    },
  ];

  const handleAction = (action, row) => {
    switch (action) {
      case "view":
        // Lógica para ver el elemento
        break;
      case "edit":
        // Lógica para editar el elemento
        break;
      case "delete":
        // Lógica para eliminar el elemento
        break;
      case "setting":
        history("/dashboard/mikrotiks/setting", { state: { rowData: row } });
        break;
      default:
        break;
    }
  };

  const columnsSuperAdministrador = [
    {
      name: "ID",
      selector: (row) => row.id_mikrotik,
      sortable: true,
      width: "60px",
    },
    {
      name: "NOMBRE ISP",
      selector: (row) => row.name_isp,
      sortable: true,
      wrap: true,
    },
  ];

  const columnFilterAdministrador = [
    {
      value: "id_mikrotik",
      text: "ID",
    },
    {
      value: "name_isp",
      text: "NOMBRE ISP",
    }
  ];

  const columnFilter = [
    {
      value: "name_mikrotik",
      text: "NOMBRE",
    },
    {
      value: "ip_mikrotik",
      text: "IP",
    }
  ];

  if (id_role == 2) {
    columns.unshift(...columnsSuperAdministrador);
    columnFilter.unshift(...columnFilterAdministrador);
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Acciones</Card.Header>
            <Card.Body>
              <NavLink
                className="btn btn-primary"
                to={"/dashboard/" + module + "/new"}
              >
                Nuevo
              </NavLink>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListC
        logOut={logOut}
        title_page={title_page}
        url={module + "/all"}
        columns={columns}
        defaultSortField={"id_mikrotik"}
        columnFilter={columnFilter}
      ></ListC>
    </Container>
  );
};

export default List;
