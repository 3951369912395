import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Spinner from "../components/Spinner";
import Home from "../pages/dashboard/Home";
import Customers from "../pages/dashboard/customers/List";
import CustomersNew from "../pages/dashboard/customers/New";
import Packages from "../pages/dashboard/packages/List";
import Zones from "../pages/dashboard/zones/List";
import ZonesNew from "../pages/dashboard/zones/New";
import Mikrotiks from "../pages/dashboard/mikrotiks/List";
import MikrotiksNew from "../pages/dashboard/mikrotiks/New";
import MikrotiksSetting from "../pages/dashboard/mikrotiks/Setting";
import Isps from "../pages/dashboard/isps/List";
import IspsNew from "../pages/dashboard/isps/New";
import IspsEdit from "../pages/dashboard/isps/Edit";
import Users from "../pages/dashboard/users/List";
import UsersNew from "../pages/dashboard/users/New";
import Instances from "../pages/dashboard/instances/List";
import InstancesNew from "../pages/dashboard/instances/New";
import InstancesView from "../pages/dashboard/instances/View";
import Clients from "../pages/dashboard/clients/List";
import ClientsNew from "../pages/dashboard/clients/New";
import ClientsView from "../pages/dashboard/clients/View";
import ClientsEdit from "../pages/dashboard/clients/Edit";
import Addresses from "../pages/dashboard/addresses/List";
import AddressesNew from "../pages/dashboard/addresses/New";
import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useIsMobile } from "../provider/MobileProvider";

const DashBoard = ({ logOut }) => {
  const { isMobile } = useIsMobile();
  const [showMenu, setShowMenu] = useState(!isMobile);

  useEffect(() => {
    setShowMenu(!isMobile);
  }, [isMobile]);

  //const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const token = localStorage.getItem("token_user");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "users/user_data", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem("name_user", response.data.data.name_person);
        localStorage.setItem(
          "lastname_user",
          response.data.data.lastname_person
        );
        localStorage.setItem("name_role", response.data.data.name_role);
        localStorage.setItem("id_role", response.data.data.id_role);
      })
      .catch((AxiosError) => {
        if (AxiosError.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          console.log(AxiosError.response.status);

          if (AxiosError.response.status === 401) {
            logOut();
          }
        } else if (AxiosError.request) {
          alert(
            "No se ha recibido una respuesta del servidor. Si el problema persiste contacte al administrador."
          );
          // La solicitud fue realizada pero no se recibió ninguna respuesta
          console.log(AxiosError.request);
        } else {
          // Ocurrió un error durante la solicitud
          console.log("Error", AxiosError.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const SetShowMenu = () => {
    setShowMenu(!showMenu);
  };

  /*
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // Cambiar a true si la resolución es menor o igual a 768px
      setShowMenu(!(window.innerWidth <= 768));
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
*/
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Container fluid className="p-0">
        <DivContent className={showMenu ? "" : "hiddenMenu"}>
          <div className={showMenu ? "divSidebar" : "divSidebar hiddenMenu"}>
            <SideBar />
          </div>
          <div className="divMain">
            <NavBar
              logOut={logOut}
              showMenu={showMenu}
              SetShowMenu={SetShowMenu}
            />
            <Routes>
              <Route
                path="/home"
                element={<Home logOut={logOut} title_page="Home" />}
              />
              <Route
                path="/customers/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={<Customers logOut={logOut} title_page="CLIENTES" />}
                      />
                      <Route
                        path="/new"
                        element={
                          <CustomersNew logOut={logOut} title_page="NUEVO CLIENTE" />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/packages/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={<Packages logOut={logOut} title_page="PAQUETES" />}
                      />
                      <Route
                        path="/new"
                        element={
                          <ZonesNew logOut={logOut} title_page="Nueva Zona" />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/zones/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={<Zones logOut={logOut} title_page="Zonas" />}
                      />
                      <Route
                        path="/new"
                        element={
                          <ZonesNew logOut={logOut} title_page="Nueva Zona" />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/mikrotiks/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Mikrotiks logOut={logOut} title_page="Mikrotiks" />
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <MikrotiksNew
                            logOut={logOut}
                            title_page="Nuevo Mikrotik"
                          />
                        }
                      />
                      <Route
                        path="/setting"
                        element={
                          <MikrotiksSetting
                            logOut={logOut}
                            title_page="Configuración de Mikrotik"
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/users/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Users logOut={logOut} title_page="Usuarios" />
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <UsersNew
                            New
                            logOut={logOut}
                            title_page="Nuevo Usuario"
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/isps/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={<Isps logOut={logOut} title_page="ISPs" />}
                      />
                      <Route
                        path="/new"
                        element={
                          <IspsNew New logOut={logOut} title_page="Nuevo ISP" />
                        }
                      />
                      <Route
                        path="/edit/:id"
                        element={
                          <IspsEdit
                            View
                            logOut={logOut}
                            title_page="EDITAR ISP"
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/Instances/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Instances logOut={logOut} title_page="INSTANCIAS" />
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <InstancesNew
                            New
                            logOut={logOut}
                            title_page="NUEVA INSTANCIA"
                          />
                        }
                      />
                      <Route
                        path="/view/:id"
                        element={
                          <InstancesView
                            View
                            logOut={logOut}
                            title_page="Ver Inistancia"
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/clients/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Clients logOut={logOut} title_page="CLIENTES" />
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <ClientsNew
                            New
                            logOut={logOut}
                            title_page="NUEVO CLIENTE"
                          />
                        }
                      />
                      <Route
                        path="/view/:id"
                        element={
                          <ClientsView
                            View
                            logOut={logOut}
                            title_page="Ver Cliente"
                          />
                        }
                      />
                      <Route
                        path="/edit/:id"
                        element={
                          <ClientsEdit
                            View
                            logOut={logOut}
                            title_page="EDITAR CLIENTE"
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              <Route
                path="/addresses/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Addresses logOut={logOut} title_page="Direcciones" />
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <AddressesNew
                            New
                            logOut={logOut}
                            title_page="Nueva direccion"
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
            </Routes>
          </div>
        </DivContent>
      </Container>
    </>
  );
};

export default DashBoard;

const DivContent = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 250px auto;
  position: relative;
  transition: grid-template-columns 1s ease;
  &.hiddenMenu {
    grid-template-columns: 0 auto;
  }

  .divSidebar {
    height: 100%;
    transition: transform 1s ease;
    z-index: 1;
  }

  @media only screen and (max-width: 767px) {
    .divSidebar {
      position: absolute;
    }
    .divMain {
      width: 100vw;
    }
  }

  .hiddenMenu {
    transform: translateX(-250px);
  }

  .divMain {
  }
`;
