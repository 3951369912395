import { Link } from "react-router-dom";

const LinkCustom = ({ paragraph, topage="/", text="link" }) => {
  return (
    <p>
    {paragraph}<Link to={topage}>{text}</Link>
  </p>
  );
};

export default LinkCustom;
