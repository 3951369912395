import { Container, Row,Col} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";


const View = ({ logOut, title_page }) => {
  document.title = title_page;
  const { id } = useParams();
  const [routes, setRoutes] = useState([]);
  const [client, setClient] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get(process.env.REACT_APP_API + "clients?id_client=" + id);
        setClient(response1.data.data[0]);

        const url = client?.typename_client === 2
          ? "clients/get_routes_isp_instance?isp_client=" + client?.isp_client + "&instance_client=" + client?.instance_client
          : "clients/get_routes_instance?instance_client=" + client?.instance_client;

        const response2 = await axios.get(process.env.REACT_APP_API + url);
        setRoutes(response2.data.data);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          logOut();
        }
      }
    };

    fetchData();
  }, [id, client?.typename_client, client?.isp_client, client?.instance_client]);


  return (
    <Container className="pt-4">
        <Row>
        <Col>
          <h3>{title_page}</h3>
        </Col>
      </Row>
      <Row>
      <p>ID del cliente: {id}</p>
      </Row>
    </Container>
  );
};

export default View;
