import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa"; // Importa los iconos de React Icons

const schema = yup.object().shape({
  route: yup
    .string()
    .matches(
      /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2})$/,
      "Formato incorrecto"
    )
    .required("Este campo es requerido"),
});

const New = ({ logOut, title_page }) => {
  document.title = title_page;
  const [isps, setIsps] = useState([]);
  const [instances, setInstances] = useState([]);
  const [typenames, setTypenames] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [id_typenames, setId_typenames] = useState(0);
  const [id_instance, setInstance] = useState(0);
  const [id_isp, setIsp] = useState(0);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  let history = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "isps")
      .then((response) => setIsps(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
    axios
      .get(process.env.REACT_APP_API + "instances/all")
      .then((response) => setInstances(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
    axios
      .get(process.env.REACT_APP_API + "typenames")
      .then((response) => setTypenames(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  const addRouteAll = () => {
    setRoutes([]);

    var url= id_typenames==2 ? "clients/routes_isp_instance?isp_client="+id_isp+"&instance_client=" +id_instance : "clients/routes_instance?instance_client=" +id_instance;
    axios
      .get(
        process.env.REACT_APP_API +url
      )
      .then((response) => setRoutes(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  };

  const addRoute = () => {
    const nuevasRutas = [...routes, {route:""}];
    setRoutes(nuevasRutas);
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    // Extraer solo la propiedad "route" de cada objeto en el arreglo routes
    const formattedRoutes = routes.map((routeObj) => routeObj.route);

    // Asignar las rutas formateadas al objeto parameters
    parameters.routes = formattedRoutes;
    console.log(parameters);

    setLoadingAdd(true);
    axios
      .post(process.env.REACT_APP_API + "clients", parameters)
      .then((response) => {
        console.log("Success", response);
        setModal({
          title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
          body: response.data.message,
          secondary: response.data.success ? "TERMINAR" : null,
          primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
        });
        openModal();
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const handleSelectChangeTypenames = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setId_typenames(selectedValue);
  };

  const handleSelectChangeInstance = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setInstance(selectedValue);
  };

  const handleSelectChangeIsp = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setIsp(selectedValue);
  };

  const deleteRoute = (index) => {
    // Crea una copia del array de rutas para evitar modificar el estado directamente
    const updatedRoutes = [...routes];

    // Elimina el elemento en el índice proporcionado
    updatedRoutes.splice(index, 1);
    // Actualiza el estado con el nuevo array sin el elemento eliminado
    setRoutes(updatedRoutes);
  };

  const handleRouteChange = (index, value) => {
    const updatedRoutes = [...routes];
    updatedRoutes[index].route = value;
    setRoutes(updatedRoutes);
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>NUEVO CLIENTE</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>CLIENTE</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="typename_client">
                      <Form.Label>TIPO:</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("typename_client")}
                        onChange={handleSelectChangeTypenames}
                      >
                        <option key="0" value="0">
                          {typenames.length === 0
                            ? "Cargando..."
                            : "SELECCIONE UN TIPO"}
                        </option>
                        {typenames.map((option) => (
                          <option
                            key={option.id_typename}
                            value={option.id_typename}
                          >
                            {option.name_typename}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.typename_client && (
                        <span style={{ color: "red" }}>
                          {errors.isp_zone.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  {(id_typenames == 2 || id_typenames == 3) && (
                    <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                      <Form.Group controlId="isp_client">
                        <Form.Label>ISP:</Form.Label>
                        <Form.Control as="select" {...register("isp_client")}  onChange={handleSelectChangeIsp}>
                          <option key="0" value="0">
                            {isps.length === 0 ? "Cargando..." : "SELECCIONE"}
                          </option>
                          {isps.map((option) => (
                            <option key={option.id_isp} value={option.id_isp}>
                              {option.name_isp}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.isp_client && (
                          <span style={{ color: "red" }}>
                            {errors.isp_zone.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm="12" md="4" lg="4" xl="3" xxl="3">
                    <Form.Group controlId="instance_client">
                      <Form.Label>INSTANCIA:</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("instance_client")}
                        onChange={handleSelectChangeInstance}
                      >
                        <option key="0" value="0">
                          {instances.length === 0
                            ? "Cargando..."
                            : "SELECCIONE"}
                        </option>
                        {instances.map((option) => (
                          <option
                            key={option.id_instance}
                            value={option.id_instance}
                          >
                            {option.id_instance}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.instance_client && (
                        <span style={{ color: "red" }}>
                          {errors.instance_client.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  {routes.map((route, index) => (
                    <Col key={index} sm="12" md="4" lg="4" xl="3" xxl="3">
                      <Form.Group controlId={`routes[${index}]`}>
                        <Form.Label>RUTA {index + 1}:</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Requerido"
                            value={route.route}
                            onChange={(e) =>
                              handleRouteChange(index, e.target.value)
                            }
                            required
                          />
                          <Button
                            variant="danger"
                            onClick={() => deleteRoute(index)}
                          >
                            <FaTrash />
                          </Button>
                        </InputGroup>
                        {errors.routes && (
                          <span style={{ color: "red" }}>
                            {errors.routes.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                {id_typenames !=3 && (
                  <Button
                    type="button"
                    className="me-2"
                    variant="primary"
                    onClick={addRouteAll}
                  >
                    AGREGAR TODAS LAS RUTAS
                  </Button>
                )}
                <Button
                  type="button"
                  className="me-2"
                  variant="primary"
                  onClick={addRoute}
                >
                  AGREGAR RUTA
                </Button>
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "Agregando" : "Agregar"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default New;
