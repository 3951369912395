import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa"; // Importa los iconos de React Icons

const schema = yup.object().shape({
  route: yup
    .string()
    .matches(
      /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2})$/,
      "Formato incorrecto"
    )
    .required("Este campo es requerido"),
});

const Edit = ({ logOut, title_page }) => {
  document.title = title_page;
  const [routes, setRoutes] = useState([]);
  const [client, setClient] = useState([]);
  const [isLoadingSubmit, setLoadingAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  let history = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "clients?id_client=" + id)
      .then((response) => setClient(response.data.data[0]))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });

    axios
      .get(process.env.REACT_APP_API + "clients/routes_client?id_client=" + id)
      .then((response) => setRoutes(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const terminate = () => {
    closeModal();
    history(-1);
  };

  const addAgain = () => {
    reset();
    closeModal();
  };

  const addRoute = () => {
    const nuevasRutas = [...routes, {route:""}];
    setRoutes(nuevasRutas);
  };

  const deleteRoute = (index) => {
    // Crea una copia del array de rutas para evitar modificar el estado directamente
    const updatedRoutes = [...routes];

    // Elimina el elemento en el índice proporcionado
    updatedRoutes.splice(index, 1);

    // Actualiza el estado con el nuevo array sin el elemento eliminado
    setRoutes(updatedRoutes);
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token_user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const onSubmit = (parameters) => {
    // Extraer solo la propiedad "route" de cada objeto en el arreglo routes
    const formattedRoutes = routes.map((routeObj) => routeObj.route);

    // Asignar las rutas formateadas al objeto parameters
    parameters.routes = formattedRoutes;
    console.log(parameters);

    setLoadingAdd(true);
    axios
      .patch(process.env.REACT_APP_API + "clients/routes", parameters)
      .then((response) => {
        console.log("Success", response);
        setModal({
          title: response.data.success ? "¡CORRECTO!" : "¡ERROR!",
          body: response.data.message,
          secondary: response.data.success ? "TERMINAR" : null,
          primary: response.data.success ? "AGREGAR OTRO" : "¡ACEPTAR!",
        });
        openModal();
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 404) {
          logOut();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const addRouteAll = () => {
    setRoutes([]);

    var url= client?.typename_client==2 ? "clients/routes_isp_instance?isp_client="+client?.isp_client+"&instance_client=" +client?.instance_client : "clients/routes_instance?instance_client=" +client?.instance_client;
    axios
      .get(
        process.env.REACT_APP_API +url
      )
      .then((response) => setRoutes(response.data.data))
      .catch((AxiosError) => {
        console.log(AxiosError);
        if (AxiosError.response.status === 401) {
          logOut();
        }
      });
  };

  const handleRouteChange = (index, value) => {
    const updatedRoutes = [...routes];
    updatedRoutes[index].route = value;
    setRoutes(updatedRoutes);
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h3>EDITAR CLIENTE</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Card>
              <Card.Header>CLIENTE</Card.Header>
              <Card.Body>
                <Row>
                  <Form.Control
                    type="hidden"
                    {...register("id_client")}
                    placeholder="Requerido"
                    value={id}
                  />
                  {routes.map((route, index) => (
                    <Col key={index} sm="12" md="4" lg="4" xl="3" xxl="3">
                      <Form.Group controlId={`routes[${index}]`}>
                        <Form.Label>RUTA {index + 1}:</Form.Label>
                        <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Requerido"
                            value={route.route}
                            onChange={(e) =>
                              handleRouteChange(index, e.target.value)
                            }
                            required
                          />
                          <Button
                            variant="danger"
                            onClick={() => deleteRoute(index)}
                          >
                            <FaTrash />
                          </Button>
                        </InputGroup>
                        {errors.routes && (
                          <span style={{ color: "red" }}>
                            {errors.routes.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
              {client?.typename_client && (
                  <Button
                    type="button"
                    className="me-2"
                    variant="primary"
                    onClick={addRouteAll}
                  >
                    AGREGAR TODAS LAS RUTAS
                  </Button>
                )}
                <Button
                  type="button"
                  className="me-2"
                  variant="primary"
                  onClick={addRoute}
                >
                  AGREGAR RUTA
                </Button>
                <NavLink className="btn btn-secondary" to={-1}>
                  CANCELAR
                </NavLink>
                <Button
                  type="submit"
                  className="ms-2"
                  variant="success"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? "ACTUALIZANDO" : "ACTUALIZAR"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.body}</Modal.Body>
        <Modal.Footer>
          {modal?.secondary && (
            <Button variant="secondary" onClick={terminate}>
              {modal?.secondary}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={modal?.title === "¡CORRECTO!" ? addAgain : closeModal}
          >
            {modal?.primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Edit;
